import { Pipe, PipeTransform } from '@angular/core';
import { WeatherDescriptionPipeAR } from './weather-descriptionAR.pipe';

@Pipe({
  name: 'weatherDescription'
})
export class WeatherDescriptionPipe implements PipeTransform {
  transform(value: number): string {
    if(typeof value === 'string'){
      value = Number(value)
    }
    switch (value) {
      case 0:
        return "A weather code could not be determined";
      case 1:
      case 101:
        return "Clear sky";
      case 2:
      case 102:
        return "Partly cloudy";
      case 3:
      case 103:
        return "Partly cloudy";
      case 4:
      case 104:
        return "Cloudy";
      case 5:
      case 105:
        return "Rain";
      case 6:
      case 106:
        return "Rain and snow / sleet";
      case 7:
      case 107:
        return "Snow";
      case 8:
      case 108:
        return "Rain shower";
      case 9:
      case 109:
        return "Snow shower";
      case 10:
      case 110:
        return "Sleet shower";
      case 11:
      case 111:
        return "Light Fog";
      case 12:
      case 112:
        return "Dense fog";
      case 13:
      case 113:
        return "Freezing rain";
      case 14:
      case 114:
        return "Thunderstorms";
      case 15:
      case 115:
        return "Drizzle";
      case 16:
      case 116:
        return "Sandstorm";
      default:
        return "Unknown weather description";
    }
  }
}
