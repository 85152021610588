import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weatherDescriptionAR'
})
export class WeatherDescriptionPipeAR implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 0:
        return "قيد التحديث";
      case 1:
      case 101:
        return "صحو";
      case 2:
      case 102:
        return "غائم جزئي";
      case 3:
      case 103:
        return "غائم جزئي";
      case 4:
      case 104:
        return "غائم";
      case 5:
      case 105:
        return "أمطار";
      case 6:
      case 106:
        return "أمطار – برد";
      case 7:
      case 107:
        return "ثلوج";
      case 8:
      case 108:
        return "أمطار";
      case 9:
      case 109:
        return "ثلوج خفيفة";
      case 10:
      case 110:
        return "أمطار - برد";
      case 11:
      case 111:
        return "ضباب خفيف";
      case 12:
      case 112:
        return "ضباب كثيف";
      case 13:
      case 113:
        return "أمطار ثلجية";
      case 14:
      case 114:
        return "سحب رعدية";
      case 15:
      case 115:
        return "أمطار خفيفة";
      case 16:
      case 116:
        return "عاصفة رملية";
      default:
        return "وصف طقس غير معروف";
    }
  }
}
