<!-- <div class="main-container-page">
  <div class="page-titlealert">
    <i class="fas fa-caret-right"></i>
    <p class="active">{{regionPage?.AlertEditor}}</p>
  </div>
</div> -->

<div class="main-container">
  <div class="bg">
    <div class="region-loc">
      <div class="parent-addLocHeading">
        <div class="addLocHeading">
          <span class="title">{{regionPage?.AddlocationbyRegion}}</span>
        </div>
      </div>
      <div class="first-slot d-flex flex-wrap">
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="custom-dropdown col" appearance="outline">
          <mat-label>{{regionPage?.Regions}}</mat-label>
          <mat-select [formControl]="selectedRegion" (valueChange)="onRegionChange($event, true, true)">
            <input matInput [formControl]="filterRegions" [placeholder]="regionPage?.Search" matSelectFilter [section]="'Reg'" [control]="filterRegions" [items]="regions" [language]="language" (filteredItems)="updateFilteredItems($event, 'Reg')" class="packageinputfilter">
            <mat-option *ngFor="let region of filteredReg" [value]="region?.id">
              <ng-container *ngIf="language === 'en' else arabic">
                {{region?.name_en}}
              </ng-container>
              <ng-template #arabic>
                {{region?.name_ar}}
              </ng-template>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" [ngClass]="{'disabled': governates.length == 0}"
          class="custom-dropdown col" appearance="outline">
          <mat-label>{{regionPage?.Governorates}}</mat-label>
          <mat-select [formControl]="selectedGovernates" [disabled]="governates.length === 0" multiple>
            <mat-select-trigger>
              <ng-container>
                {{ language === 'en' ? preSelectedAdvanceCustomMannedGovernorates[0]?.nameEn : preSelectedAdvanceCustomMannedGovernorates[0]?.nameAr }}
              </ng-container>
              <span class="additional-selection" *ngIf="preSelectedAdvanceCustomMannedGovernorates?.length > 1">
                <ng-container *ngIf="preSelectedAdvanceCustomMannedGovernorates?.[0] === undefined">
                  (+{{ preSelectedAdvanceCustomMannedGovernorates?.length - 2 }}
                  {{ preSelectedAdvanceCustomMannedGovernorates?.length === 2 ? "other" : "others" }})
                </ng-container>
                <ng-container *ngIf="preSelectedAdvanceCustomMannedGovernorates?.[0] !== undefined">
                  (+{{ preSelectedAdvanceCustomMannedGovernorates?.length - 1 }}
                  {{ preSelectedAdvanceCustomMannedGovernorates?.length === 2 ? "other" : "others" }})
                </ng-container>
              </span>
            </mat-select-trigger>
            <!-- <mat-option selectAll [allValues]="allGovernatesIds" style="min-height: 40px;">{{regionPage?.SelectAll}}</mat-option> -->
            <mat-option (click)="toggleAllGovernorates()" [value]="allGovernateId">{{regionPage?.SelectAll}}</mat-option>
            <input matInput [formControl]="filterGovernorates" [placeholder]="regionPage?.Search" (filteredItems)="updateFilteredItems($event, 'Gov')" class="packageinputfilter" (keydown.space)="$event.stopPropagation()">
            <mat-option *ngFor="let governate of filteredGov" [value]="governate.id" (click)="checkIfAllMannedIsSelected(governate.id);">
              <ng-container *ngIf="language === 'en' else arabic">
                {{governate?.nameEn}}
              </ng-container>
              <ng-template #arabic>
                {{governate?.nameAr}}
              </ng-template>
            </mat-option>

            <!-- if we have any previously selected goverates, we want to show atleast one option otherwise <mat-select-trigger> doesn't show  -->
            <ng-container *ngIf="preSelectedAdvanceCustomMannedGovernorates.length !== 0">
              <mat-option *ngFor="let gov of preSelectedAdvanceCustomMannedGovernorates"  [value]="gov.id" class="d-none"></mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>


        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" [ngClass]="{'disabled': municipalities?.length == 0 || selectedGovernates.value.length === 0}"
          class="custom-dropdown col" appearance="outline">
          <mat-label>{{regionPage?.Municipalities}}</mat-label>
          <mat-select [formControl]="selectedMunicipalities" [disabled]="municipalities?.length === 0" multiple (valueChange)="onLocChange()">
            <mat-select-trigger>
              <ng-container *ngFor="let groupMunc of filteredGroupedMuncipalities">
                <ng-container *ngFor="let municipality of groupMunc?.data">
                  <ng-container *ngIf="selectedMunicipalities.value[0] === undefined">
                    <ng-container *ngIf="selectedMunicipalities.value?.[1] === municipality?.id">
                      {{language === 'en' ? municipality?.nameEn : municipality?.nameAr}}
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="selectedMunicipalities.value[0] !== undefined">
                    <ng-container *ngIf="selectedMunicipalities.value?.[0] === municipality?.id">
                      {{language === 'en' ? municipality?.nameEn : municipality?.nameAr}}
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
              <span class="additional-selection" *ngIf="(selectedMunicipalities.value?.length || 0) > 1">
                <ng-container *ngIf="selectedMunicipalities.value[0] === undefined">
                  (+{{ (selectedMunicipalities.value?.length || 0) - 2 }}
                  {{ selectedMunicipalities.value?.length === 2 ? "other" : "others" }})
                </ng-container>
                <ng-container *ngIf="selectedMunicipalities.value[0] !== undefined">
                  (+{{ (selectedMunicipalities.value?.length || 0) - 1 }}
                  {{ selectedMunicipalities.value?.length === 2 ? "other" : "others" }})
                </ng-container>
              </span>
            </mat-select-trigger>
            <mat-option selectAll [allValues]="allMunicipalityIds" style="min-height: 40px;">{{regionPage?.SelectAll}}</mat-option>
            <input matInput [formControl]="filterMunicipality" [placeholder]="regionPage?.Search" matSelectFilter [section]="'Munc'" [control]="filterMunicipality" [items]="groupedMuncipalities" [language]="language" (filteredItems)="updateFilteredItems($event, 'Munc')" class="packageinputfilter">
            <ng-container *ngFor="let groupMunc of filteredGroupedMuncipalities">
              <mat-optgroup [label]="language === 'en' ? groupMunc?.nameEn : groupMunc?.nameAr">
                <mat-option *ngFor="let municipality of groupMunc?.data" [value]="municipality?.id">
                  <ng-container *ngIf="language === 'en' else arabic">
                    {{municipality?.nameEn}}
                  </ng-container>
                  <ng-template #arabic>
                    {{municipality?.nameAr}}
                  </ng-template>
                </mat-option>
              </mat-optgroup>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" [ngClass]="{'disabled': OtherMunicipalities?.length === 0}"
          class="custom-dropdown col" appearance="outline">
          <mat-label>{{regionPage?.OtherLocations}}</mat-label>
          <mat-select [(ngModel)]="selectedOtherLocations" [disabled]="OtherMunicipalities?.length === 0" multiple>
            <ng-container *ngFor="let municipality of OtherMunicipalities">
              <mat-option [value]="municipality?.id">
                {{language === 'en' ? municipality?.nameEn : municipality?.nameAr}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="governates.length > 0" class="parent-addLocHeading borderBottom px-3 pt-3">
      <div class="addLocHeading gap-3">
        <h3>{{regionPage?.Location}}</h3>
        <p>
          <ng-container *ngIf="language === 'en' else arabic">
            {{selectedRegionName?.name_en}}
          </ng-container>
          <ng-template #arabic>
            {{selectedRegionName?.name_ar}}
          </ng-template>
        </p>
      </div>
    </div>

    <div *ngIf="governates.length > 0" class="d-flex">
      <div class="col-6 px-3">
        <div class="addLocHeading gap-3">
          <h3>{{regionPage?.Governorates}}</h3>
          <div>
            <p-chip class="chip" (click)="onSelectGovernate(governate.id); onLocChange()" *ngFor="let governate of governates"
              [label]="language === 'en' ? governate.nameEn : governate.nameAr"
              [styleClass]="selectedGovernates?.value?.includes(governate.id) ? 'custom-chip' : 'disable-chip'">
            </p-chip>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="addLocHeading">
          <h3 *ngIf="selectedGovernatesAndMuncipalitiesInfo.length > 0">{{regionPage?.Municipality}}</h3>
          <div class="my-3" *ngFor="let gov of selectedGovernatesAndMuncipalitiesInfo">
            <h3 class="my-2">{{language == 'en' ? gov?.nameEn : gov?.nameAr }}</h3>
            <div class="d-flex flex-wrap gap-2 align-items-center">
              <button *ngFor="let munc of gov?.muncipalities" (click)="removeMunicipalities(munc?.id); onLocChange()"
                class="chip-underline">
                <ng-container>
                  {{ language == 'en' ? munc?.nameEn : munc?.nameAr}}
                </ng-container>
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="region-loc-alert">
      <div class="addLocHeading my-2">
        <h3>{{regionPage?.AddAlert}} </h3>
      </div>

      <div class="d-flex align-items-center gap-2 row">
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="custom-dropdown col" appearance="outline">
          <mat-label>{{regionPage?.AlertAction}}</mat-label>
          <mat-select [formControl]="selectedAlertAction" multiple>
            <mat-option [value]="action.id" *ngFor="let action of alertActions">
              {{language === 'en' ? action?.nameEn : action?.nameAr}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="custom-dropdown col" appearance="outline">
          <mat-label>{{regionPage?.AlertType}}</mat-label>
          <mat-select [formControl]="selectedAlertType" (valueChange)="onChangeAlertType($event, true)">
            <mat-select-trigger>
              <ng-container *ngFor="let type of alertTypes">
                <div *ngIf="selectedAlertType.value === type.id" class="mat-option-text">
                  <span class="color-span" [ngClass]="{'yellow': type?.color.toLowerCase()  === 'yellow', 'red': type?.color.toLowerCase()  === 'red',
                  'orange': type?.color.toLowerCase()  === 'orange'}"></span>
                  <span>{{language === 'en' ? type?.color : type?.nameAr}}</span>
                </div>
              </ng-container>
            </mat-select-trigger>
            <mat-option *ngFor="let type of alertTypes" [value]="type?.id">
              <span class="color-span" [ngStyle]="{ 'background-color': type?.color }"></span>
              <span>{{language === 'en' ? type?.color : type?.nameAr}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" [ngClass]="{'disabled': alertStatus.length == 0}"
          class="custom-dropdown col" appearance="outline">
          <mat-label>{{regionPage?.AlertStatus}}</mat-label>
          <mat-select [formControl]="selectedAlertStatus" [disabled]="alertStatus?.length === 0"
            (valueChange)="getAlertHazards($event, true)">
            <input matInput [formControl]="filterStatus" [placeholder]="regionPage?.Search" matSelectFilter [section]="'Gov'" [control]="filterStatus" [items]="alertStatus" [language]="language" (filteredItems)="updateFilteredItems($event, 'Status')" class="packageinputfilter">
            <mat-option *ngFor="let status of filteredStatus" [value]="status.id">
              {{ language === 'en' ? status.nameEn : status.nameAr }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" [ngClass]="{'disabled': alertHazards.length == 0}"
          class="custom-dropdown col" appearance="outline">
          <mat-label>{{regionPage?.AlertHazard}}</mat-label>
          <mat-select [formControl]="selectedAlertHazard" multiple>
            <mat-select-trigger>
              <ng-container *ngFor="let hazard of alertHazards">
                <ng-container *ngIf="selectedAlertHazard?.value?.[0] === undefined">
                  <ng-container *ngIf="selectedAlertHazard?.value?.[1] === hazard?.id">
                    {{language === 'en' ? hazard?.nameEn : hazard?.nameAr}}
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="selectedAlertHazard?.value?.[0] !== undefined">
                  <ng-container *ngIf="selectedAlertHazard?.value?.[0] === hazard?.id">
                    {{language === 'en' ? hazard?.nameEn : hazard?.nameAr}}
                  </ng-container>
                </ng-container>
              </ng-container>
              <span class="additional-selection" *ngIf="(selectedAlertHazard?.value?.length || 0) > 1">
                <ng-container *ngIf="selectedAlertHazard?.value?.[0] === undefined">
                  (+{{ (selectedAlertHazard?.value?.length || 0) - 2 }}
                  {{ selectedAlertHazard?.value?.length === 2 ? "other" : "others" }})
                </ng-container>
                <ng-container *ngIf="selectedAlertHazard?.value?.[0] !== undefined">
                  (+{{ (selectedAlertHazard.value?.length || 0) - 1 }}
                  {{ selectedAlertHazard.value?.length === 2 ? "other" : "others" }})
                </ng-container>
              </span>
            </mat-select-trigger>
            <mat-option selectAll [allValues]="allHazardIds" style="min-height: 40px;">{{regionPage?.SelectAll}}</mat-option>
            <mat-option *ngFor="let hazard of alertHazards" [value]="hazard.id">
              <ng-container *ngIf="language === 'en' else arabic">
                {{hazard?.nameEn}}
              </ng-container>
              <ng-template #arabic>
                {{hazard?.nameAr}}
              </ng-template>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <hr style="border: 1px solid #7070703b;" />

      <div class="d-flex align-items-center gap-2 row">
        <!-- <mat-form-field (click)="check(calendarstart)" style="cursor: pointer;"
          [dir]="language === 'en' ? 'ltr' : 'rtl'" #startDateContainer
          class="col mat-input-field-container calendar-input" id="startDate" appearance="outline">
          <p-calendar #calendarstart (onSelect)="onStartDateSelect($event, true)" class="position-static no-hov" appendTo="body" [disabled]="isUpdate && isAlreadyPublished"
            [minDate]="today" [showIcon]="true" inputId="icon"></p-calendar>
          <mat-label>{{regionPage?.Startdate}}</mat-label>
          <input [formControl]="selectedStartDate" matInput [placeholder]="regionPage?.Starttime" readonly>
        </mat-form-field>

        <mat-form-field (click)="check(calendarend)" style="cursor: pointer;" [dir]="language === 'en' ? 'ltr' : 'rtl'"
          #endDateContainer class="col mat-input-field-container calendar-input" appearance="outline">
          <p-calendar #calendarend (onSelect)="onEndDateSelect($event, true)" class="position-static no-hov" appendTo="body"
            [minDate]="today" [showIcon]="true" inputId="icon"></p-calendar>
          <mat-label>{{regionPage?.Enddate}}</mat-label>
          <input [formControl]="selectedEndDate" matInput [placeholder]="regionPage?.Enddate" readonly>
        </mat-form-field> -->
        <form [formGroup]="dateForm" class="d-flex gap-4" style="width: 50%;">
          <mat-form-field class="col mat-input-field-container calendar-input" appearance="outline">
            <input
              matInput
              [placeholder]="regionPage?.Starttime"
              name="dpStart"
              formControlName="selectedStartDate"
              ngbDatepicker
              #startDate="ngbDatepicker"
              [readOnly]="true"
              [minDate]="{year: currentDate.year, month: currentDate.month, day: currentDate.day}"
              (dateSelect)="onStartDateSelect($event, true)"
            />
            <mat-label>{{ regionPage?.Startdate }}</mat-label>
            <button matSuffix mat-icon-button type="button" (click)="startDate.toggle()">
              <mat-icon>calendar_today</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="col mat-input-field-container calendar-input" appearance="outline">
            <input
              matInput
              [placeholder]="regionPage?.Enddate"
              name="dpEnd"
              formControlName="selectedEndDate"
              ngbDatepicker
              #endDate="ngbDatepicker"
              [readOnly]="true"
              [minDate]="{year: currentDate.year, month: currentDate.month, day: currentDate.day}"
              (dateSelect)="onEndDateSelect($event, true)"
            />
            <mat-label>{{ regionPage?.Enddate }}</mat-label>
            <button matSuffix mat-icon-button type="button" (click)="endDate.toggle()">
              <mat-icon>calendar_today</mat-icon>
            </button>
          </mat-form-field>
        </form>



        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="col mat-input-field-container time-input"
          appearance="outline">
          <span (click)="startTimeInput.click()" class="clock-icon">
            <i class="far fa-clock"></i>
          </span>
          <mat-label>{{regionPage?.Starttime}}</mat-label>
          <input style="border: none !important;" #startTimeInput matInput [formControl]="selectedStartTime" (click)="onStartTimeSelect()"
            [placeholder]="regionPage?.Starttime" aria-label="default time" [ngxTimepicker]="startTime" readonly>
          <ngx-material-timepicker #startTime></ngx-material-timepicker>
        </mat-form-field>

        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="col mat-input-field-container time-input"
          appearance="outline">
          <span (click)="endTimeInput.click()" class="clock-icon">
            <i class="far fa-clock"></i>
          </span>
          <mat-label>{{regionPage?.Endtime}}</mat-label>
          <input style="border: none !important;" #endTimeInput matInput [formControl]="selectedEndTime" (click)="onEndTimeSelect()"
            [placeholder]="regionPage?.Endtime" aria-label="default time" [ngxTimepicker]="endTime" readonly>
          <ngx-material-timepicker #endTime></ngx-material-timepicker>
        </mat-form-field>
      </div>

      <div class="d-flex gap-3 row mt-4">
        <div class="col">
          <div class="addLocHeading pb-3">
            <h3>
              {{regionPage?.Message}}
            </h3>
          </div>
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="example-full-width text-area message-label"
            appearance="fill">
            <mat-label>
              {{regionPage?.Leaveacomment}}
            </mat-label>
            <textarea [formControl]="message" rows="4" matInput [placeholder]="regionPage?.WeatherSeverity"></textarea>
          </mat-form-field>
          <!-- <p class="max-character">
            {{regionPage?.MaxCharacter250words}}
          </p> -->
        </div>
        <div class="col">
          <div class="addLocHeading pb-3">
            <h3>
              {{regionPage?.MessageForXField}}
            </h3>
          </div>
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="example-full-width text-area message-label"
            appearance="fill">
            <mat-label>
              {{regionPage?.WriteaPost}}
            </mat-label>
            <textarea style="direction: rtl;text-align: right;font-family: 'Somar';font-size: 15px;" [formControl]="x_post" rows="4" matInput [placeholder]="regionPage?.WeatherSeverity"></textarea>
          </mat-form-field>
          <!-- <p class="max-character">
            {{regionPage?.MaxCharacter250words}}
          </p> -->
        </div>
        <div class="col">
          <div class="addLocHeading pb-3">
            <h3>
              {{regionPage?.UploadAttachment}}
            </h3>
          </div>
          <div class="d-flex gap-2">
            <input (change)="handleFileChange($event)" #fileBtn class="d-none" type="file" accept=".pdf, .png, .jpg">
            <button (click)="triggerFileInput()" class="polyBtn polySave2">
              <div class="savebg2">
                <div>
                  <span>
                  </span>
                </div>
              </div>
              {{regionPage?.Browse}}
            </button>
            <p class="no-file">{{fileName ? fileName : regionPage?.Nofileselected}}</p>
          </div>
          <p class="no-file mt-1 mb-0 ms-0 me-0">.pdf/.png/.jpg</p>
          <p class="max-file mt-2 mb-0 ms-0 me-0">{{regionPage?.MaxFileSize}}</p>
        </div>
      </div>

      <!-- <div class="d-flex gap-3 align-items-center mt-4">
        <div class="addLocHeading pt-2">
          <h3>{{regionPage?.SelectFromMap || 'Select Governorates from map'}}</h3>
        </div>
        <div>
          <div class="customtoggleone pt-2">
            <input [(ngModel)]="isLeafletMap" type="checkbox" id="toggle-button-checkbox">
            <label class="toggle-button-switch" for="toggle-button-checkbox"></label>
            <div class="toggle-button-text">
              <div class="toggle-button-text-on" style="color: transparent;">0</div>
              <div class="toggle-button-text-off" style="color: transparent;">0</div>
            </div>
          </div>
        </div>
      </div>

      <div class="polygonMap position-relative mt-30">
        <div #mapContainer tabindex="0" [ngStyle]="{'z-index': isLeafletMap ? '-1' : '1'}"
          class="polygonMap leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom">
        </div>
        <div #mapContainerLeaf tabindex="0" [ngStyle]="{'z-index': isLeafletMap ? '1' : '-1'}"
          class="polygonMap leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom">
        </div>
      </div>
    </div> -->

    <div #mapContainerLeaf tabindex="0"
      class="polygonMap leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom">
    </div>

    <div class="field-checkbox row">
      <div class="col">
        <p-checkbox [formControl]="enableTwitterNotification" [binary]="true"></p-checkbox>
        <label for="binary">
          {{regionPage?.GetupdatesandnotificationsdeliveredtoyourTwitter}}
        </label>
      </div>

      <div class="col">
        <p-checkbox [formControl]="enableSMSNotification" [binary]="true"></p-checkbox>
        <label for="binary">
          {{regionPage?.GetupdatesandnotificationsviaSMS}}
        </label>
      </div>

      <div class="col">
        <p-checkbox [formControl]="enableEmailNotification" [binary]="true"></p-checkbox>
        <label for="binary">
          {{regionPage?.GetupdatesandnotificationsviaEmail}}
        </label>
      </div>

      <div class="col">
        <p-checkbox [(ngModel)]="enableEmailGroups" [binary]="true" (ngModelChange)="checkALLGroups()"></p-checkbox>
        <label for="binary">
          {{regionPage?.PublishEWSAlertforUserGroups}}<br>
          <span style="text-decoration: underline;color: #0051ac; cursor: pointer;" (click)="openModalForEmails($event, usergroup)">{{regionPage?.ChangeUserGroups}}</span>
        </label>
        <!-- <input type="checkbox" class="form-check-input" id="addemailgroup" formControlName="addEmailGroup" (change)="openModalForEmails($event, usergroup)">
          <label for="addemailgroup" class="form-check-label">{{ automaticdetailPage?.AddEmailGroups }}</label> -->
      </div>

      <ng-template #usergroup let-modal>
        <div class="modal-header">
          <h3 class="modalheadtext m-0" id="modal-basic-title">{{ automaticdetailPage?.AddEmailGroups }}</h3>
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click');modalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="d-flex align-items-center gap-2 px-3">
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"  class="custom-dropdown sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12 w-50" appearance="outline">
              <mat-label>{{ automaticdetailPage?.AddEmailGroups }}</mat-label>
              <mat-select [(ngModel)]="selectedEmailGroups" multiple msInfiniteScroll (infiniteScroll)="getUserGroupsForDropdown()" floatLabel="always">
                <mat-select-trigger>
                  <ng-container *ngFor="let ugroups of listUserGroups">
                    <ng-container *ngIf="selectedEmailGroups[0] === undefined">
                      <ng-container *ngIf="selectedEmailGroups?.[1] === ugroups?.id">
                        {{ugroups?.name}}
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="selectedEmailGroups[0] !== undefined">
                      <ng-container *ngIf="selectedEmailGroups?.[0] === ugroups?.id">
                        {{ugroups?.name}}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <span class="additional-selection" *ngIf="(selectedEmailGroups?.length || 0) > 1">
                    <ng-container *ngIf="selectedEmailGroups[0] === undefined">
                      (+{{ (selectedEmailGroups?.length || 0) - 2 }}
                      {{ selectedEmailGroups?.length === 2 ? "other" : "others" }})
                    </ng-container>
                    <ng-container *ngIf="selectedEmailGroups[0] !== undefined">
                      (+{{ (selectedEmailGroups?.length || 0) - 1 }}
                      {{ selectedEmailGroups?.length === 2 ? "other" : "others" }})
                    </ng-container>
                  </span>
                </mat-select-trigger>
                <mat-option selectAll [allValues]="allUGIds" style="min-height: 40px;">{{regionPage?.SelectAll}}</mat-option>
                <input matInput [formControl]="filterUG" [placeholder]="regionPage?.Search" matSelectFilter [section]="'UG'" [control]="filterUG" [items]="listUserGroups" [language]="language" (filteredItems)="updateFilteredItems($event, 'UG')" class="packageinputfilter">
                <ng-container *ngFor="let group of filterlistUserGroups">
                  <mat-option [value]="group?.id">{{ group?.name }}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="previewbtn" (click)="modal.close('Save click');modalSave()">{{ automaticdetailPage?.Save }}</button>
        </div>
      </ng-template>
    </div>

    <div class="d-flex justify-content-end align-items-center" style="gap: 20px;">
      <!-- p-button-text fontSizeAppBtn saveDraftBtn -->
      <button *ngIf="!isAlreadyPublished" type="button" (click)="onSave(false)"
        class="canceltxt d-flex justify-content-center align-items-center">{{ regionPage?.Saveasdraft }}</button>
      <!-- p-button-outlined publishBtn fontSizeAppBtn -->
      <button (click)="onfly()" type="button"
        class="publishbtn d-flex justify-content-center align-items-center">{{ regionPage?.Publish }}</button>
      <!-- bgBtn fontSizeAppBtn -->
      <button type="button" (click)="GotoPreview()"
        class="previewbtn d-flex justify-content-center align-items-center">{{ regionPage?.Preview }}</button>
    </div>
  </div>
</div>

<div class="preview-backdrop" [ngStyle]="viewPreview ? {} : {'z-index': '-1', 'position': 'absolute'}" (click)="viewPreview = false"></div>
<div class="preview-div" [ngStyle]="viewPreview ? {} : {'z-index': '-1', 'position': 'absolute'}">
  <app-preview *ngIf="workPreview" [ngStyle]="viewPreview ? {} : {'z-index': '-1', 'position': 'absolute'}" (xpostImgSubject)="onSave(true, true, $event)" #preview [navigationExtras]="navigationExtras"></app-preview>
</div>
