<div *ngIf="isDivVisible" class="deletemodalbg">
  <div class="deletemodal">
    <i class="far fa-times-circle dltcross" (click)="cancel()"></i>
    <p style="font: normal normal 400 1.5vw Roboto;color: #0051AC;text-align: center;margin-bottom: 2vw;">
      {{clientsPage?.AreYouSure}}
    </p>
    <!-- <p style="font: normal normal 400 1vw Roboto;text-align: center;color: #49586C;margin-bottom: 2vw;">
      {{clientsPage?.DoYouReallyWant}}</p> -->
    <div class="polyBtnDiv d-flex align-items-center justify-content-center gap-5 btn-div">
      <button class="save-btn" (click)="deletePackageBasedOnCondition(deleteData)">
        <i class="fas fa-trash-alt"></i>
        {{clientsPage?.Delete}}
      </button>
      <button class="cancel-btn" (click)="cancel()">
        <i class="fas fa-times"></i>
        {{clientsPage?.Cancel}}
      </button>
    </div>
  </div>
</div>

<!-- <div class="page-titlealert">
    <i class="fas fa-caret-right"></i>
    <p class="active">Packages</p>
  </div> -->

<!-- <div class="page-header justify-content-between">
  <div class="d-flex gap-3 align-items-center">
    <img class="headerIcon" src="../../../assets/addusers.svg" />
    <h3>Create Packages</h3>
  </div>
  <div class="d-flex align-items-center">
    <button [disabled]="apiGroups.length == 0" (click)="toggleUpsertPackageWindow(true)" pButton type="button"
      class="page-header-btn" [label]="'Create Packages'">
      <img src="../../../assets/addIcon.svg" /></button>
  </div>
</div> -->

<p-toolbar class="row" style="margin: inherit;">
  <div class="p-toolbar-group-left col justify-content-between">
    <div class="d-flex gap-2 align-items-center">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 28 28">
        <defs>
          <linearGradient id="linear-gradient" x1="0.5" y1="0.374" x2="1" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stop-color="#0051ac"/>
            <stop offset="1" stop-color="#00a3e2"/>
          </linearGradient>
        </defs>
        <g id="Group_14761" data-name="Group 14761" transform="translate(-2207 253)">
          <g id="Group_14760" data-name="Group 14760" transform="translate(142 -152)">
            <path id="Rectangle_6607" data-name="Rectangle 6607" d="M6,0H28a0,0,0,0,1,0,0V22a6,6,0,0,1-6,6H0a0,0,0,0,1,0,0V6A6,6,0,0,1,6,0Z" transform="translate(2065 -101)" fill="url(#linear-gradient)"/>
            <g id="Group_14759" data-name="Group 14759" transform="translate(-7 -1)">
              <g id="Group_14756" data-name="Group 14756" transform="translate(0 -1)">
                <circle id="Ellipse_10" data-name="Ellipse 10" cx="1.5" cy="1.5" r="1.5" transform="translate(2076 -92)" fill="#fff"/>
                <rect id="Rectangle_6608" data-name="Rectangle 6608" width="15" height="3" transform="translate(2081 -92)" fill="#fff"/>
              </g>
              <g id="Group_14757" data-name="Group 14757" transform="translate(0 5)">
                <circle id="Ellipse_10-2" data-name="Ellipse 10" cx="1.5" cy="1.5" r="1.5" transform="translate(2076 -92)" fill="#fff"/>
                <rect id="Rectangle_6608-2" data-name="Rectangle 6608" width="15" height="3" transform="translate(2081 -92)" fill="#fff"/>
              </g>
              <g id="Group_14758" data-name="Group 14758" transform="translate(0 11)">
                <circle id="Ellipse_10-3" data-name="Ellipse 10" cx="1.5" cy="1.5" r="1.5" transform="translate(2076 -92)" fill="#fff"/>
                <rect id="Rectangle_6608-3" data-name="Rectangle 6608" width="15" height="3" transform="translate(2081 -92)" fill="#fff"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <div class="alert-history">
        {{packagesPage?.PackagesAPI}}
      </div>
    </div>
    <div class="d-flex">
      <button [disabled]="apiGroups.length == 0" (click)="toggleUpsertPackageWindow(true, true)" class="package-btn" *ngxPermissionsOnly="['create_package']">
        <!-- <svg id="Group_118119" data-name="Group 118119" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.494" height="20" viewBox="0 0 19.494 20">
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_15632" data-name="Rectangle 15632" width="19.494" height="20" transform="translate(0 0)" class="svg"/>
            </clipPath>
          </defs>
          <g id="Group_117814" data-name="Group 117814" transform="translate(0 0)" clip-path="url(#clip-path)">
            <path id="Path_65455" data-name="Path 65455" d="M659.927,681.675a3.579,3.579,0,1,0,0,5.064,3.558,3.558,0,0,0,0-5.064m-.658,1.858-1.611,1.947a.317.317,0,0,1-.489,0l-1.043-1.261a.317.317,0,0,1,.488-.4l.8.966,1.367-1.652a.317.317,0,0,1,.488.4" transform="translate(-641.48 -667.786)" class="svg"/>
            <path id="Path_65456" data-name="Path 65456" d="M19.009,0H14.468l-2.1,3.813h5.626Z" transform="translate(-12.132 -0.003)" class="svg"/>
            <path id="Path_65457" data-name="Path 65457" d="M352.246,3.816,351.228,0H348.46l-1.018,3.813Z" transform="translate(-340.888 -0.003)" class="svg"/>
            <path id="Path_65458" data-name="Path 65458" d="M340.769,242.09l2.348-1.481a.337.337,0,0,1,.359,0l2.349,1.481-.023-4.356h-5.01Z" transform="translate(-334.34 -233.249)" class="svg"/>
            <path id="Path_65459" data-name="Path 65459" d="M591.612,3.816,589.509,0h-4.541l1.018,3.813Z" transform="translate(-573.932 -0.003)" class="svg"/>
            <path id="Path_65460" data-name="Path 65460" d="M13.029,246.784a4.087,4.087,0,0,1,4.883-.675v-8.375H12.133l.026,4.965a.337.337,0,0,1-.336.338.33.33,0,0,1-.179-.052L8.956,241.29l-2.688,1.694a.336.336,0,0,1-.515-.285l.026-4.965H0v14.258H12.558a4.084,4.084,0,0,1,.472-5.208m-11.2,3.744h-.37a.317.317,0,0,1,0-.634h.37a.317.317,0,0,1,0,.634m2.248,0H3.066a.317.317,0,0,1,0-.634H4.074a.317.317,0,0,1,0,.634" transform="translate(0 -233.249)" class="svg"/>
          </g>
        </svg> -->
        {{packagesPage?.CreatePackage}}
      </button>
    </div>
  </div>
</p-toolbar>

<app-upsert-packages *ngIf="isUpsertPackageWindowOpen" #UpsertPackagesComponent [ngClass]="upsertdisplay ? '' : 'd-none'"
  (closeWindow)="toggleUpsertPackageWindow($event)" (getPackagesList)="getPackagesList()" (getAPIList)="getAPIList()"
  [apiGroups]="apiGroups" [throttlingData]="throttlingData" [SMS_ALLOCATION_DROPDOWN_OPTIONS]="SMS_ALLOCATION_DROPDOWN_OPTIONS">
</app-upsert-packages>

<div class="form">
  <div ngbNav #nav="ngbNav" [(activeId)]="activeNav" [destroyOnHide]="false">
    <div [ngbNavItem]="navItems[0]">
      <ng-template ngbNavContent>
        <div class="AddUserTable" *ngxPermissionsOnly="['list_package']">
          <!-- <div class="heading-container">
            <h3>{{packagesPage?.Packages}}</h3>
          </div> -->
          <div class="d-flex flex-wrap justify-content-between align-items-center" style="padding: 1.2rem 1.188rem;">
            <div class="col-xl-3 col-lg-12 col-12">
              <div class="d-flex gap-4">
                <h3 class="switching-list-btn active" (click)="onNavChange('Packages')">{{packagesPage?.Packages}}</h3>
                <h3 class="switching-list-btn inactive" (click)="onNavChange('APIs')">{{packagesPage?.APIs || 'APIs'}}</h3>
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-end col-xl-9 col-lg-12 col-12">
              <div class="search-locations d-flex col-xl-3 col-lg-12 col-12 px-1">
                <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="w-100 client-type-select">
                  <mat-label>{{clientsPage?.SearchByApiGroup}}</mat-label>
                  <mat-select floatLabel="always" (valueChange)="onAPIfilter($event)">
                    <input matInput [placeholder]="clientsPage?.Search" [formControl]="searchInputControl" class="packageinputfilter">
                    <mat-option *ngIf="!isLoadinglistApiGroup">{{ clientsPage?.None}}</mat-option>
                    <mat-option *ngIf="isLoadinglistApiGroup">
                      <div class="d-flex justify-content-center">
                        <i class="fa fa-spinner spinner--icon-select"></i>
                      </div>
                    </mat-option>
                    <ng-container *ngFor="let api_groups of tableAPIGroup; let i = index">
                      <mat-option *ngIf="i > 0" [value]="api_groups?.id">
                        {{language === 'en' ? api_groups?.name : api_groups?.name_ar}}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="search-locations d-flex col-xl-3 col-lg-12 col-12 px-1">
                <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="client-type-select w-100" appearance="outline">
                  <mat-label>{{clientsPage?.SearchByName}}</mat-label>
                  <input type="text" matInput (keyup)="onTypeEmail($event)">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="client-table mb-30">
            <table class="customTable">
              <thead>
                <tr>
                  <th>{{packagesPage?.Name}}</th>
                  <!-- <th>{{packagesPage?.TenureinYears}}</th> -->
                  <th class="cursor-pointer" (click)="getPackagesList('sortBytenure')"> <!-- AlertStatus -->
                    <div class="d-flex align-items-center gap-2">
                      {{packagesPage?.TenureinYears}}
                      <div class="d-flex flex-column justify-content-center">
                        <i [ngClass]="getListPackageModel.sortBytenure === 'asc' ? 'sort-active' : ''"
                          class="fas fa-sort-up sort-icon"></i>
                        <i [ngClass]="getListPackageModel.sortBytenure === 'desc' ? 'sort-active' : ''"
                          class="fas fa-sort-down sort-icon"></i>
                      </div>
                    </div>
                  </th>
                  <!-- <th>{{packagesPage?.Price}}</th> -->
                  <th class="cursor-pointer" (click)="getPackagesList('sortByprice')"> <!-- AlertStatus -->
                    <div class="d-flex align-items-center gap-2">
                      {{packagesPage?.Price}}
                      <div class="d-flex flex-column justify-content-center">
                        <i [ngClass]="getListPackageModel.sortByprice === 'asc' ? 'sort-active' : ''"
                          class="fas fa-sort-up sort-icon"></i>
                        <i [ngClass]="getListPackageModel.sortByprice === 'desc' ? 'sort-active' : ''"
                          class="fas fa-sort-down sort-icon"></i>
                      </div>
                    </div>
                  </th>
                  <!-- <th>{{packagesPage?.MaxLocations}}</th> -->
                  <th class="cursor-pointer" (click)="getPackagesList('sortByMaxLocations')"> <!-- AlertStatus -->
                    <div class="d-flex align-items-center gap-2">
                      {{packagesPage?.MaxLocations}}
                      <div class="d-flex flex-column justify-content-center">
                        <i [ngClass]="getListPackageModel.sortByMaxLocations === 'asc' ? 'sort-active' : ''"
                          class="fas fa-sort-up sort-icon"></i>
                        <i [ngClass]="getListPackageModel.sortByMaxLocations === 'desc' ? 'sort-active' : ''"
                          class="fas fa-sort-down sort-icon"></i>
                      </div>
                    </div>
                  </th>
                  <th>{{packagesPage?.AssignedUsers}}</th>
                  <!-- <th>{{packagesPage?.MaxUsers}}</th> -->
                  <th class="cursor-pointer" (click)="getPackagesList('sortByMaxUsers')"> <!-- AlertStatus -->
                    <div class="d-flex align-items-center gap-2">
                      {{packagesPage?.MaxUsers}}
                      <div class="d-flex flex-column justify-content-center">
                        <i [ngClass]="getListPackageModel.sortByMaxUsers === 'asc' ? 'sort-active' : ''"
                          class="fas fa-sort-up sort-icon"></i>
                        <i [ngClass]="getListPackageModel.sortByMaxUsers === 'desc' ? 'sort-active' : ''"
                          class="fas fa-sort-down sort-icon"></i>
                      </div>
                    </div>
                  </th>
                  <th>{{packagesPage?.SMSLimit || 'SMS Limit'}}</th>
                  <th>{{packagesPage?.ApiGroup}}</th>
                  <th>{{packagesPage?.Actions}}</th>
                </tr>
              </thead>
              <tbody *ngIf="!isLoadingListAPI">
                <tr *ngFor="let package of listPackages?.data index as j">
                  <td>{{package?.name || 'N/A'}}</td>
                  <td>{{package?.tenure || 'N/A'}}</td>
                  <td>{{package?.price + ' SAR' || 'N/A'}}</td>
                  <td>{{package?.max_locations || 'N/A'}}</td>
                  <td>{{package?.assign_users}}</td>
                  <td>{{package?.max_users || 'N/A'}}</td>
                  <td class="text-capitalize">{{package?.SMSLimit || 'N/A'}}</td>
                  <!-- <td>
                    <div class="my-3" *ngFor="let group of package?.api_groups">
                      {{group?.name || 'N/A'}}
                    </div>
                  </td> -->
                  <td class="governorate-body">
                    <div class="d-flex flex-wrap"
                        [ngClass]="{
                          'expanded-governorates': isExpanded2[j],
                          'collapsed-governorates': !isExpanded2[j],
                          'gap-2': package?.api_groups.length > 1
                        }"
                      >
                      <ng-container *ngIf="package?.api_groups.length < 2">
                        <span class="collapse-btn"></span>
                      </ng-container>
                      <div *ngIf="package?.api_groups.length > 2" class="d-flex align-items-center"
                        [ngClass]="isExpanded2[j] ? 'expanded-governorates' : 'collapsed-governorates'">
                        <i [ngClass]="isExpanded2[j] ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'"
                          class="fas collapse-btn" (click)="$event.stopPropagation();toggleExpand2(j)"
                          style="color: #149edf;"></i>
                      </div>
                      <div class="col-10 d-flex flex-column justify-content-center">
                        <ng-container *ngFor="let group of package?.api_groups; index as index">
                          {{ language === 'en' ? group?.name : group?.name_ar }}
                          <ng-container *ngIf="index !== package?.api_groups.length - 1"> -
                            <pre *ngIf="index % 2 === 1"></pre>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center gap-3 flex-nowrap">
                      <button (click)="setEditPackageForm(package, 'Packages')" *ngxPermissionsOnly="['update_package']"
                        [ngbTooltip]="packagesPage?.EditPackage" tooltipClass="custom-tooltip">
                        <img src="../../../../assets/alerteditor/edit-icon-small.png">
                      </button>
                      <button (click)="show(package?.id, false)" *ngxPermissionsOnly="['delete_package']"
                        [ngbTooltip]="packagesPage?.Delete" tooltipClass="custom-tooltip">
                        <img src="../../../../assets/alerteditor/delete-icon.png">
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="d-flex justify-content-center no-data" *ngIf="isLoadingListAPI; else noPackagesRecordTemplate">
            <i class="fa fa-spinner spinner--icon"></i>
          </div>
          <ng-template #noPackagesRecordTemplate>
            <div *ngIf="listPackages?.data?.length === 0 || !listPackages?.data" class="no-data text-center">
              {{packagesPage?.CurrentlyNoPackagestoShow}}
            </div>
          </ng-template>
          <div *ngIf="listPackages?.paginationVariables?.pageCount > 1 && !isLoadingListAPI"
            class="col-12 d-flex justify-content-center pagination dir-left mb-30">
            <ngb-pagination (pageChange)="onPageChange($event)" [maxSize]="3" [rotate]="true" [pageSize]="pageSize" [(page)]="page"
              [collectionSize]="listPackages?.paginationVariables?.totalCount"></ngb-pagination>
          </div>
        </div>
      </ng-template>
    </div>
    <div [ngbNavItem]="navItems[1]" [destroyOnHide]="true">
      <ng-template ngbNavContent>
        <div class="AddUserTable" *ngxPermissionsOnly="['list_package']">
          <!-- <div class="heading-container">
            <h3>{{packagesPage?.Packages}}</h3>
          </div> -->
          <div class="d-flex flex-wrap justify-content-between align-items-center" style="padding: 1.2rem 1.188rem;">
            <div class="col-xl-3 col-lg-12 col-12">
              <div class="d-flex gap-4">
                <h3 class="switching-list-btn inactive" (click)="onNavChange('Packages')">{{packagesPage?.Packages}}</h3>
                <h3 class="switching-list-btn active" (click)="onNavChange('APIs')">{{packagesPage?.APIs || 'APIs'}}</h3>
              </div>
            </div>
            <div class="d-flex flex-wrap justify-content-end col-xl-9 col-lg-12 col-12">
              <div class="search-locations d-flex col-xl-3 col-lg-12 col-12 px-1">
                <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="client-type-select w-100" appearance="outline">
                  <mat-label>{{clientsPage?.SearchByName}}</mat-label>
                  <input type="text" matInput (keyup)="onTypeSearch($event)">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="client-table mb-30">
            <table class="customTable">
              <thead>
                <tr>
                  <th>{{packagesPage?.Name}}</th>
                  <!-- <th>{{packagesPage?.TenureinYears}}</th> -->
                  <th class="cursor-pointer" (click)="getAPIList('sortBytenure')"> <!-- TenureinYears -->
                    <div class="d-flex align-items-center gap-2">
                      {{packagesPage?.TenureinYears}}
                      <div class="d-flex flex-column justify-content-center">
                        <i [ngClass]="getListAPIModel.sortBytenure === 'asc' ? 'sort-active' : ''"
                          class="fas fa-sort-up sort-icon"></i>
                        <i [ngClass]="getListAPIModel.sortBytenure === 'desc' ? 'sort-active' : ''"
                          class="fas fa-sort-down sort-icon"></i>
                      </div>
                    </div>
                  </th>
                  <!-- <th>{{packagesPage?.Price}}</th> -->
                  <th class="cursor-pointer" (click)="getAPIList('sortByprice')"> <!-- Price -->
                    <div class="d-flex align-items-center gap-2">
                      {{packagesPage?.Price}}
                      <div class="d-flex flex-column justify-content-center">
                        <i [ngClass]="getListAPIModel.sortByprice === 'asc' ? 'sort-active' : ''"
                          class="fas fa-sort-up sort-icon"></i>
                        <i [ngClass]="getListAPIModel.sortByprice === 'desc' ? 'sort-active' : ''"
                          class="fas fa-sort-down sort-icon"></i>
                      </div>
                    </div>
                  </th>
                  <!-- <th>{{packagesPage?.Status}}</th> -->
                  <!-- <th class="cursor-pointer" (click)="getAPIList('sortByStatus')">
                    <div class="d-flex align-items-center gap-2">
                      {{clientsPage?.Status}}
                      <div class="d-flex flex-column justify-content-center">
                        <i [ngClass]="getListAPIModel.sortByStatus === 'asc' ? 'sort-active' : ''"
                          class="fas fa-sort-up sort-icon"></i>
                        <i [ngClass]="getListAPIModel.sortByStatus === 'desc' ? 'sort-active' : ''"
                          class="fas fa-sort-down sort-icon"></i>
                      </div>
                    </div>
                  </th> -->
                  <!-- <th>{{packagesPage?.Throttling}}</th> -->
                  <th class="cursor-pointer" (click)="getAPIList('sortByThrottling')"> <!-- Throttling -->
                    <div class="d-flex align-items-center gap-2">
                      {{packagesPage?.Throttling}}
                      <div class="d-flex flex-column justify-content-center">
                        <i [ngClass]="getListAPIModel.sortByThrottling === 'asc' ? 'sort-active' : ''"
                          class="fas fa-sort-up sort-icon"></i>
                        <i [ngClass]="getListAPIModel.sortByThrottling === 'desc' ? 'sort-active' : ''"
                          class="fas fa-sort-down sort-icon"></i>
                      </div>
                    </div>
                  </th>
                  <th>{{packagesPage?.Actions}}</th>
                </tr>
              </thead>
              <tbody *ngIf="!isLoadingListAPI">
                <tr *ngFor="let package of listWSOPackages?.data index as j">
                  <td>{{package?.name || 'N/A'}}</td>
                  <td>{{package?.tenure || 'N/A'}}</td>
                  <td>{{package?.price + ' SAR' || 'N/A'}}</td>
                  <!-- <td>{{package?.status || 'N/A'}}</td> -->
                  <td>
                    {{ language === 'en' ? package?.throttling : package?.throttling_ar }}
                  </td>
                  <td>
                    <div class="d-flex align-items-center gap-3 flex-nowrap">
                      <button (click)="setEditPackageForm(package, 'APIgroups')" *ngxPermissionsOnly="['update_package']"
                        [ngbTooltip]="packagesPage?.EditPackage" tooltipClass="custom-tooltip">
                        <img src="../../../../assets/alerteditor/edit-icon-small.png">
                      </button>
                      <button (click)="show(package?.id, true)" *ngxPermissionsOnly="['delete_package']"
                        [ngbTooltip]="packagesPage?.Delete" tooltipClass="custom-tooltip">
                        <img src="../../../../assets/alerteditor/delete-icon.png">
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="d-flex justify-content-center no-data" *ngIf="isLoadingListAPI; else noRecordsTemplate">
            <i class="fa fa-spinner spinner--icon"></i>
          </div>
          <ng-template #noRecordsTemplate>
            <div *ngIf="listWSOPackages?.data?.length === 0 || !listWSOPackages?.data" class="no-data text-center">{{packagesPage?.CurrentlyNoPackagestoShow}}</div>
          </ng-template>
          <div *ngIf="listWSOPackages?.paginationVariables?.pageCount > 1 && !isLoadingListAPI"
            class="col-12 d-flex justify-content-center pagination dir-left mb-30">
            <ngb-pagination (pageChange)="onWSOPageChange($event)" [maxSize]="3" [rotate]="true" [pageSize]="pageSize" [(page)]="pageAPI"
              [collectionSize]="listWSOPackages?.paginationVariables?.totalCount"></ngb-pagination>
          </div>
        </div>
      </ng-template>
    </div>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
