export const environmentBaseUrl = {
  // for dev env
  // url: 'https://ncmportal.centric.ae',
  // for lower dev env
  url: 'https://devncmportal.centricdxb.com',
  // url: 'http://ncm_portal.test',
  // now useless
  // url: 'https://apidemoajwaa.ncm.gov.sa',
  // upper env
  // url: 'https://demoapimeteoksa.ncm.gov.sa',
  // prod env
  // url: 'https://apimeteoksa.ncm.gov.sa',

  environmentBaseUrl: {esriKey: ""},
  esriKey:"9r3e_EjqP69x3uby0hiyJ9gGeZ5N0_Bba_0tx4e3zWtipOepeuxU9wvaWunKYob_a8y2oZhNByflCNRdljgmmMg2gHI2O0IQbckh9RXd2hq60FzR2r0lGWTnLkFrKVQKsYeZ9rJiMSwBtzOGwFeEc5NVaHd5vIc9QPaTrg3c-MQP27zLzr-jef-IXKZaLPG3",
}
